<template>
  <div class="view pa24">
    <div class="d-flex">
      <div>
        <el-input class="w220 mr10" v-model="name" clearable placeholder="手机号" />
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
    >
      <template v-slot:table>
        <el-table-column prop="fansId" align="center" label="粉丝id" />
        <el-table-column align="center" show-overflow-tooltip label="微信头像" >
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 50px"
                :src="scope.row.wxImg"
                :preview-src-list="srcList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="nickname" align="center" label="昵称" />
        <el-table-column prop="tel" align="center" label="电话" />
        <el-table-column prop="money" align="center" label="消费金额" />
        <el-table-column prop="visitCount" align="center" label="访问次数" />
        <el-table-column align="center" label="分组">
          <template slot-scope="scope">
            <el-select v-model="scope.row.groupName" placeholder="未分组"  @change="toFZ(scope.row)">
              <el-option
                  v-for="item in typeList"
                  :key="item.fansGroupId"
                  :label="item.name"
                  :value="item"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
      </template>
    </commonTable>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {selectAssFansPageList,selectFansGroupPageList,upFansGroupByUserId} from '@/api/members'
let base64 = require('js-base64').Base64
export default {
  name: "fansList",
  components: {
    commonTable
  },
  data() {
    return {
      name:'',
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      keyWord: "", //文章标题
      pickerValue: "", //选择时间
      centerDialogVisible: false,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      tableData: [],
      typeList:[],
      type:'',
      srcList:[]
    };
  },
  created() {
    this.getGroupList();
    this.queryPage();
  },
  computed: {

  },
  methods: {
    async getGroupList() {
      let data = {
        pageSize: 999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await selectFansGroupPageList(data);
        this.loading = false;
        const { total, list } = result.data.pageInfo;
        this.typeList = list;
      } catch (error) {
        this.loading = false;
        this.tableData = []
      }
    },
    /**@method 获取文章管理 */
    async queryPage(payload) {
      let data = {
        associationId: localStorage.getItem("associationId"),
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        isDel:1,
        ...payload,
      };
      try {
        this.loading = true;
        const result = await selectAssFansPageList(data);
        const { total, list } = result.data.pageInfo;
        let a = []
        for (let i in list) {
          if (list[i].wxImg) {
            a.push(list[i].wxImg)
          }
          if (list[i].nickname) {
            list[i].nickname = base64.decode(list[i].nickname);
          }
          if (list[i].money){
            list[i].money = (list[i].money/100).toFixed(2)
          }
        }
        this.srcList = a
        this.tableData = list;
        this.total = total;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryPage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.queryPage();
    },

    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        // nickName:base64.encode(this.name),
        tel:this.name,
      };
      this.queryPage(data);
    },
    async toFZ(row){
      let ajax = {
        groupId:row.groupName.fansGroupId,
        groupName:row.groupName.name,
        userId:row.userId,
      }
      try {
        await upFansGroupByUserId(ajax);
        let data = {
          nickeName: base64.encode(this.name)
        };
        await this.queryPage(data);
      } catch (error) {
        console.log(error)
      }
    }
  },
};
</script>

<style lang="scss" scoped>
</style>
