<template>
  <div class="appointmentManagement-view">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="粉丝列表" name="first"
      ><fansList v-if="activeName == 'first'" :jurisdiction="jurisdiction"
      /></el-tab-pane>
      <el-tab-pane label="粉丝分组" name="second"
      ><fansGroupList
          v-if="activeName == 'second'"
          :jurisdiction="jurisdiction"
      ></fansGroupList
      ></el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import fansList from "./fans/fansList";
import fansGroupList from "./fans/fansGroupList";
import { mapState } from 'vuex';

export default {
  name: "fansManagement",
  components: {
    fansList,
    fansGroupList,
  },
  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("041")
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
